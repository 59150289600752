.cart-product {
  position: relative;

  &__remove {
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-radius: 50%;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    font-weight: 700;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    position: absolute;
    top: 0;
    right: 0;
    transition: 0.3s;

    &:hover {
      color: $primary;
      text-decoration: none;
      background-color: $gray-300;
    }
  }

  &__frame {
    @include frame('rect', 75%);
    @include frame('fade', rgba(#fff, 0.4));
    width: 80px;
    margin-right: 10px;
    border: 1px solid $border-color;

    img {
      object-fit: contain;
    }
  }

  &__title {
    font-size: 12px;
    font-weight: 700;
    margin-right: 24px;
    margin-bottom: 8px;
  }

  &__info {
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 2px;
  }

  &__color {
    display: inline-block;
    width: 40px;
    height: 12px;
    border: 1px solid #444;
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  &__price {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    margin-left: auto;
    padding-left: 4px;
    text-align: right;
    color: $danger;
  }

  &__label {
    margin-right: 8px;
    font-size: 12px;
  }

  @media (max-width: 1199px) {
    &__label {
      display: none;
    }
  }
}

.cart {
  padding-top: 10px;

  &__table {
    margin-bottom: 0;
  }

  &__name {
    a {
      color: $gray-800;

      &:hover {
        color: $primary;
      }
    }
  }

  &__iwrap {
    display: block;
    border: 1px solid $border-color;
    background-color: $white;
    padding: 4px;
    width: 90px;
    margin: 0 auto;
  }

  &__frame {
    @include frame('rect', 75%);
  }

  &__quantity {
    .form-control {
      width: 100px;
      max-width: 100px;
      text-align: center;
    }
  }

  &__price {
    font-size: 14px;
    font-weight: 700;
    color: $danger;
    text-align: center;
    white-space: nowrap;
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    border-top: 1px solid $border-color;
    padding: 30px 0;
  }

  &__btn {
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    line-height: 35px;
    padding: 0 20px;
    border-radius: 4px;
    border: 1px solid $border-color;
    color: $gray-800;
    margin-left: 20px;
    margin-bottom: 10px;

    &:hover {
      color: $gray-800;
      text-decoration: none;
    }

    &--blue {
      background-color: #0d2470;
      border-color: #0d2470;
      color: $white;

      &:hover {
        color: $white;
      }
    }

    &--red {
      background-color: #e40d0d;
      border-color: #e40d0d;
      color: $white;

      &:hover {
        color: $white;
      }
    }
  }

  thead {
    th {
      text-align: center;
      border-bottom: 0;
      white-space: nowrap;
    }
  }

  tbody {
    tr {
      &:hover {
        background-color: rgba($primary, 0.05);
      }
    }

    td {
      vertical-align: middle;
    }
  }

  tfoot {
    td {
      vertical-align: middle;
    }
  }
}
