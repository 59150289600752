.pagination {
  margin-bottom: 0;

  .page-item {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  .page-link {
    color: $gray-800;
    padding: 8px 14px;
    border-radius: 0;
  }
}

.breadcrumb {
  margin-bottom: 16px;

  &-item {
    &,
    & + &::before,
    &.active,
    &.active::before,
    a,
    a:hover {
      color: $gray-800;
    }
  }
}

$phoneRingColor: #e73a35;
.phone-ring {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50%;
  background-color: $phoneRingColor;
  position: fixed;
  z-index: 100;
  bottom: 50px;
  left: 50px;
  box-shadow: 0 5px 20px rgba(#000, 0.3);

  &:hover {
    color: $white;
  }

  &::before {
    content: "";
    display: block;
    width: 250%;
    height: 250%;
    border-radius: 50%;
    border: 1px solid $phoneRingColor;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: phone-circle-animate 1s ease-in-out infinite;
  }

  &::after {
    content: "";
    display: block;
    width: 160%;
    height: 160%;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
    background-color: rgba($phoneRingColor, 0.5);
    animation: phone-circle-fill-animate 2s ease-in-out infinite;
  }

  &__icon {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    background-color: rgba($phoneRingColor, 0.7);
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    animation: phone-icon-animate 1s ease-in-out infinite;
  }

  &__text {
    display: inline-block;
    background-color: rgba($phoneRingColor, 0.7);
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
    padding: 0 20px 0 60px;
    color: $white;
    border-radius: 20px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    box-shadow: 0 5px 20px rgba(#000, 0.3);
  }

  &:hover &__text {
    color: $phoneRingColor;
    background-color: rgba(#fff, 0.7);
  }

  @media (max-width: 767px) {
    left: 20px;
    bottom: 20px;

    &__text {
      display: none;
    }
  }
}

@keyframes phone-circle-animate {
  0% {
    transform: translate(-50%, -50%) rotate(0) scale(0.5);
    opacity: 0.1;
  }
  30% {
    transform: translate(-50%, -50%) rotate(0) scale(0.7);
    opacity: 0.5;
  }
  100% {
    transform: translate(-50%, -50%) rotate(0) scale(1);
    opacity: 0.1;
  }
}

@keyframes phone-circle-fill-animate {
  0%,
  100% {
    transform: translate(-50%, -50%) rotate(0) scale(0.7);
    opacity: 0.2;
  }
  50% {
    transform: translate(-50%, -50%) rotate(0) scale(1);
    opacity: 0.2;
  }
}

@keyframes phone-icon-animate {
  0%,
  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }

  20%,
  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}

.contact-map {
  height: 400px;
}

.button {
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  background-color: $danger;
  color: $white;
  border-radius: 4px;
  padding: 0 16px;
  line-height: 33px;
  border: 1px solid $danger;

  &:hover {
    color: $white;
    text-decoration: none;
    background-color: darken($danger, 5%);
  }

  &--outline {
    background: 0;
    color: $danger;
    text-transform: none;
  }
}

.ct-info {
  &__title {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
}

.ct-form {
  &__title {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
}

.social-btns {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  &__item {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  &__link {
    display: flex;

    img {
      height: 24px;
    }
  }
}

.as-menu {
  list-style: none;
  margin: -16px 0;
  padding-left: 0;

  &__item {
    &:not(:last-child) {
      border-bottom: 1px solid $border-color;
    }
  }

  &__link {
    display: inline-block;
    font-size: 14px;
    color: $gray-800;
    padding: 7px 4px 6px;

    &:hover {
      color: $primary;
      text-decoration: none;
    }

    &.active {
      color: $primary;
      font-weight: 700;
    }
  }
}

.panel {
  border: 1px solid $border-color;
  border-radius: 10px;

  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-radius: 10px 10px 0 0;
    border-bottom: 1px solid $border-color;
    padding: 7px 12px;
    background-color: $border-color;
  }

  &__body {
    padding: 12px;
  }

  &__title {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: $gray-800;
    margin-bottom: 4px;

    &::after {
      content: "";
      display: block;
      flex: 1;
      border-top: 1px solid $border-color;
      margin-left: 10px;
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__list-item {
    &:not(:last-child) {
      margin-bottom: 8px;
      padding-bottom: 8px;
      border-bottom: 1px solid $border-color;
    }
  }

  &__subtitle {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 4px;
  }

  &__iwrap {
    display: block;
    width: 100px;
    padding: 4px;
    margin-right: 14px;
    border: 1px solid $border-color;
  }

  &__frame {
    @include frame("rect", 75%);
    @include frame("shine");
  }
}

.product {
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid $border-color;
  padding: 8px;

  &__frame {
    @include frame("rect", 75%);
    @include frame("shine");
  }

  &__title {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 6px;
    margin-top: 8px;

    a:hover {
      color: $danger;
    }
  }

  &__price {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
    font-size: 14px;
    font-weight: 700;
    color: $danger;
    margin-bottom: 8px;
    margin-top: auto;

    del {
      font-size: 10px;
      font-weight: 400;
      color: #777;
    }
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__btn {
    font-size: 12px;
    color: $primary;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }
}

.news {
  display: flex;
  height: 100%;
  flex-direction: column;

  &__frame {
    @include frame("rect", 56.25%);
    @include frame("zoom");
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border: 1px solid $border-color;
    border-top: 0;
    padding: 15px;
  }

  &__info {
    color: #999;
    margin-bottom: 5px;
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 10px;
    margin-bottom: 10px;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 80px;
      border-top: 1px solid $border-color;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    a:hover {
      color: $primary;
    }
  }

  &__desc {
    @include max-line(3);
    margin-bottom: 12px;
  }

  &__link {
    font-size: 14px;
    font-weight: 700;
    color: $primary;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }
}

.news-2 {
  &__frame {
    @include frame("rect", 66.66%);
    @include frame("zoom");
    width: 100px;
    margin-right: 10px;
  }

  &__title {
    font-size: 14px;
    font-weight: 400;
    margin-top: -4px;
    margin-bottom: 0;
  }

  @media (max-width: 1199px) {
    &__frame {
      width: 100px;
    }
  }
}

.news-3 {
  padding: 10px;
  background-color: #e8edfc;

  &__frame {
    @include frame("rect", 56.25%);
    @include frame("zoom");
    width: 160px;
    margin-right: 12px;
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 8px;
    margin-top: -2px;
  }

  &__desc {
    @include max-line(2);
  }
}

.post {
  &-title {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  &-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10px;
  }

  &-sapo {
    font-size: 14px;
    font-weight: 700;
  }
}

.relate-post {
  &__title {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  &__list {
    padding-left: 17px;
    margin-bottom: 0;
  }

  &__link {
    color: $gray-800;

    &:hover {
      color: $danger;
    }
  }
}

.product-info {
  &__title {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  &__detail {
    padding: 16px 0;
    margin: 16px 0;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
  }

  &__btn {
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
    color: $white;
    background-color: $danger;
    line-height: 40px;
    padding: 0 20px;
    border-radius: 4px;

    &:hover {
      background-color: darken($danger, 5%);
      color: $white;
      text-decoration: none;
    }
  }

  &__price {
    font-size: 20px;
    font-weight: 700;
    color: $danger;
  }

  &__oldprice {
    color: #999;
    margin-left: 10px;
  }

  &__hotline {
    margin-top: 10px;

    & > .fa:first-child {
      color: $danger;
    }

    a {
      color: $danger;
      font-weight: 700;
    }
  }
}

.detail-tabs {
  border-bottom: 1px solid $border-color;
  margin-bottom: 20px;

  .nav-item {
    &:not(:last-child) {
      margin-right: 24px;
    }
  }

  .nav-link {
    font-size: 14px;
    text-transform: uppercase;
    color: $gray-800;
    padding: 0;
    line-height: 40px;

    &:hover {
      color: $primary;
    }

    &.active {
      color: $primary;
      font-size: 16px;
      font-weight: 700;
      position: relative;

      &::after {
        content: "";
        display: block;
        width: 100%;
        position: absolute;
        bottom: -2px;
        left: 0;
        border-top: 3px solid $primary;
      }
    }
  }

  @media (max-width: 991px) {
    display: block;
    white-space: nowrap;
    overflow: auto;

    .nav-item {
      display: inline-block;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    .nav-link {
      &.active {
        font-size: 14px;
      }
    }
  }
}

.pd-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  padding: 1px;

  &__item {
    background-color: $white;
    box-shadow: 0 0 0 1px $border-color;
  }

  @media (max-width: 1199px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 575px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.m-card {
  display: block;
  box-shadow: 0 3px 6px rgba(#000, 0.15);

  img {
    display: block;
    width: 100%;
  }
}

.filter {
  border: 1px solid #e8edfc;
  margin-bottom: 40px;

  &__label {
    display: flex;
    align-items: center;
    white-space: nowrap;
    background-color: #e8edfc;
    align-self: stretch;
    padding: 0 20px;
    font-weight: 700;
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 15px;
  }

  &__link {
    display: inline-block;
    font-size: 14px;
    padding: 0 18px;
    line-height: 28px;
    border: 1px solid $border-color;
    margin: 5px;
    white-space: nowrap;

    &:hover {
      background-color: #e8edfc;
      text-decoration: none;
    }
  }

  @media (max-width: 991px) {
    flex-direction: column;

    &__label {
      padding: 10px 20px;
    }

    &__body {
      padding: 5px;
    }

    &__link {
      padding: 0 10px;
      font-size: 12px;
    }
  }
}

.news-tabs {
  margin-bottom: 16px;

  .nav-link {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 35px;
    padding: 0 15px;
    color: $gray-800;
    background-color: #e8edfc;

    &.active {
      color: $white;
      background-color: $primary;
    }
  }

  @media (max-width: 991px) {
    display: block;
    white-space: nowrap;
    overflow: auto;

    .nav-item {
      display: inline-block;
    }
  }
}

.home-banner-wrapper {
  @media (min-width: 1200px) {
    margin-left: -20px;
  }
}

.banner-frame {
  display: block;

  img {
    display: block;
    object-fit: cover;
    object-position: 50% 50%;
  }
}

.eval-form {
  .table {
    td {
      vertical-align: middle;
      padding: 8px 0;

      &:first-child {
        white-space: nowrap;
        padding-right: 16px;
      }
    }
  }

  .form-control {
    border-radius: 18px;
  }

  &__btn {
    @include btn("shine");
    display: inline-block;
    padding: 0 24px;
    line-height: 35px;
    border: 0;
    border-radius: 18px;
    color: $white;
    font-weight: 700;
    text-transform: uppercase;
    background-color: $primary;
  }
}

.rating-point {
  display: inline-block;
  text-align: center;
  font-size: 14px;
  line-height: 1;
  color: #666;

  &__number {
    font-size: 2.143em;
    font-weight: 700;
    color: #222;
  }
}

.rating-result {
  max-width: 360px;

  .table {
    td {
      padding: 8px 0;

      &:first-child {
        padding-right: 20px;
      }
    }
  }

  .progress {
    height: 10px;
  }
}
