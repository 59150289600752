.banner-slider {
  position: relative;
  z-index: 1;

  &__prev,
  &__next {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    border: 2px solid $white;
    color: $white;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 14px;

    &:focus {
      outline: 0;
    }
  }

  &__next {
    left: auto;
    right: 14px;
  }

  &__frame {
    img {
      display: block;
      width: 100%;
    }
  }
}
