.footer {
  background-color: $primary;
  padding-top: 40px;

  &__title {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    padding-left: 14px;
    position: relative;
    color: $white;
    margin-bottom: 6px;

    &::before {
      content: '';
      width: 3px;
      height: 17px;
      background-color: #ee6612;
      position: absolute;
      top: 4px;
      left: 0;
    }
  }

  &__text {
    color: $white;
    font-size: 14px;
    line-height: 24px;
  }

  &__map {
    height: 230px;
  }

  &__end {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__copyright {
    padding: 15px 0;
    color: $white;
  }

  @media (max-width: 767px) {
    &__end {
      flex-direction: column;
    }

    &__copyright {
      padding-bottom: 0;
    }
  }
}

.f-contact {
  color: $white;
  list-style: none;
  margin: 0;
  padding: 0;

  &__item {
    padding-left: 24px;
    position: relative;
    font-size: 14px;
    line-height: 24px;
    overflow-wrap: break-word;

    & > .fa {
      position: absolute;
      top: 4px;
      left: 0;
    }

    a {
      color: $white;

      &:hover {
        color: #ee6612;
      }
    }
  }
}

.f-menu {
  list-style: none;
  margin: 0;
  padding: 0;

  &__link {
    display: inline-block;
    padding-left: 16px;
    position: relative;
    font-size: 14px;
    line-height: 24px;
    color: $white;
    transition: 0.3s;

    &:hover {
      color: #ee6612;
      text-decoration: none;
      transform: translateX(5px);
    }

    &::before {
      content: '\f105';
      font-family: 'fontawesome';
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.f-payment {
  display: flex;
  flex-wrap: wrap;
  padding: 15px 0;

  &__label {
    color: $white;
    font-size: 14px;
    line-height: 21px;
  }

  &__link {
    display: flex;
    height: 21px;
    align-items: center;
    margin-left: 16px;

    img {
      max-height: 21px;
    }
  }
}
