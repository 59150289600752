.section {
  &__title {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
}

.section-2 {
  &__title {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  &__desc {
    margin-bottom: 30px;
  }
}

.section-3 {
  &__header {
    display: flex;
    border-bottom: 1px solid $border-color;
    margin-bottom: 25px;
  }

  &__title {
    font-size: 18px;
    line-height: 30px;
    font-weight: 700;
    text-transform: uppercase;
    color: $white;
    background-color: $primary;
    padding: 5px 20px 5px 30px;
    margin-bottom: 0;
    position: relative;
    z-index: 1;
    margin-right: 40px;

    &::before {
      content: '';
      display: block;
      width: 40px;
      height: 100%;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 100%;
      background-color: $primary;
      transform: skewX(45deg);
      transform-origin: bottom;
    }

    &::after {
      content: '';
      border: 8px solid transparent;
      border-top-color: $primary;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @media (max-width: 767px) {
    &__title {
      padding: 5px 10px 5px 20px;
      font-size: 16px;
    }
  }
}

.form {
  padding: 20px 0;

  &__title {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: $primary;
    text-align: center;
    margin-bottom: 16px;

    &::before,
    &::after {
      content: '';
      display: block;
      border-top: 1px solid $border-color;
      flex: 1 0 30px;
      margin-right: 16px;
    }

    &::after {
      margin-right: 0;
      margin-left: 16px;
    }
  }

  &__socials {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__social {
    display: inline-flex;
    align-items: center;
    margin: 5px;
    border: 1px solid $border-color;
    padding: 8px 20px;
    border-radius: 4px;
    color: #666;

    &:hover {
      color: $primary;
      text-decoration: none;
      border-color: $primary;
    }

    .fa-google-plus {
      color: $google-plus;
    }

    .fa-facebook {
      color: $facebook;
    }
  }

  &__footer {
    border-top: 1px solid $border-color;
    text-align: center;
    padding-top: 20px;
    margin-top: 20px;

    a {
      color: $primary;
      font-weight: 700;
      text-transform: uppercase;
      text-decoration: underline;
    }
  }
}

.aside {
  &__title {
    font-size: 16px;
    font-weight: 700;
    color: $white;
    text-align: center;
    text-transform: uppercase;
    background-color: $primary;
    padding: 10px;
    margin-bottom: 0;
  }

  &__body {
    padding: 16px;
    border: 1px solid $border-color;
  }
}
