$gutters: 4 8 10 16 20 30 40 50 60 80 100;

@each $gutter in $gutters {
  .gutter-#{$gutter} {
    margin: 0 -#{$gutter / 2}px;
    & > [class^='col'] {
      padding: 0 #{$gutter / 2}px;
    }
  }
}

@each $gutter in $gutters {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      .gutter-over-#{$breakpoint}-#{$gutter} {
        margin: 0 -#{$gutter / 2}px;
        & > [class^='col'] {
          padding: 0 #{$gutter / 2}px;
        }
      }
    }

    @include media-breakpoint-down($breakpoint) {
      .gutter-under-#{$breakpoint}-#{$gutter} {
        margin: 0 -#{$gutter / 2}px;
        & > [class^='col'] {
          padding: 0 #{$gutter / 2}px;
        }
      }
    }
  }
}

// add column 20% (5 items in a row)
.col-20 {
  flex: 0 0 20%;
  max-width: 20%;
  padding: 0 15px;
}

@each $key, $val in $grid-breakpoints {
  .col-#{$key}-20 {
    padding: 0 15px;
  }
  @media (min-width: #{$val}) {
    .col-#{$key}-20 {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
}

.grid {
  display: flex;

  &__main {
    flex: 0 0 calc(100% - 300px);
    max-width: calc(100% - 300px);
    order: 1;
    margin-left: auto;
  }

  &__aside {
    flex: 0 0 270px;
    max-width: 270px;
  }

  @media (max-width: 1199px) {
    &__main {
      flex: 0 0 calc(100% - 270px);
      max-width: calc(100% - 270px);

      &--lg {
        width: 100%;
        max-width: 100%;
        order: 0;
        margin: 0;
      }
    }

    &__aside {
      flex: 0 0 240px;
      max-width: 240px;
    }
  }

  @media (max-width: 991px) {
    flex-direction: column;

    &__main {
      width: 100%;
      max-width: 100%;
      order: 0;
      margin: 0;
    }

    &__aside {
      width: 100%;
      max-width: 100%;
      margin-top: 30px;
    }
  }
}
