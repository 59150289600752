.header {
  position: relative;
  z-index: 100;

  &__inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
  }

  &__logo {
    display: inline-flex;
    margin: 20px auto;

    img {
      height: 80px;
    }
  }

  &__btn {
    position: absolute;
    top: 60px;
    right: 0;
    transform: translateY(-50%);
  }

  &__desc {
    flex: 0 0 100%;
    margin-bottom: 20px;
  }

  &__text-1 {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__text-2 {
    font-size: 14px;
    font-weight: 700;

    a {
      color: $primary;

      &:hover {
        color: $primary;
        text-decoration: none;
      }
    }
  }

  &__text-3 {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: $danger;

    a {
      color: $danger;

      &:hover {
        color: $danger;
      }
    }
  }

  &__text-4 {
    font-weight: 700;
  }

  @media (min-width: 1200px) {
    &__logo {
      margin: 20px 0;

      img {
        height: 130px;
      }
    }

    &__btn {
      display: none;
    }

    &__desc {
      flex: 0 0 auto;
      margin-left: 64px;
      margin-bottom: 0;
    }

    &__text-1 {
      font-size: 20px;
    }

    &__text-3 {
      font-size: 20px;
    }

    &__text-4 {
      font-size: 16px;
    }
  }
}

.navigation {
  background-color: $primary;
  position: relative;
  z-index: 100;

  &__inner {
    display: flex;
    align-items: center;
    position: relative;
  }
}

.h-nav {
  width: 270px;
  position: relative;
  z-index: 10;
  margin-right: auto;

  &__label {
    display: block;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 50px;
    text-align: center;
    color: $white;
    box-shadow: 0 1px 3px rgba(#000, 0.5);
    cursor: pointer;
  }

  &__menu {
    display: none;
    width: 100%;
    @include shadow("");
    position: absolute;
    top: 100%;
    left: 0;
    background-color: $white;
    list-style: none;
    margin: 0;
    padding: 0 10px;
  }

  &__item {
    &--group {
      position: relative;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $border-color;
    }
  }

  &__link {
    display: block;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 10px 0;
    color: $gray-800;
    transition: 0.3s;

    &:hover {
      color: $primary;
      text-decoration: none;
      transform: translateX(5px);
    }
  }

  &__item--group &__link {
    padding-right: 24px;
  }

  &__toggle {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: 0;
    right: -5px;
    width: 40px;
    height: 40px;
    z-index: 10;

    .fa {
      display: flex;
      width: 30px;
      height: 30px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      font-size: 16px;
      transition: 0.3s;
    }

    &:hover .fa {
      background-color: $light;
    }

    &.active .fa {
      transform: rotateZ(90deg);
    }
  }

  &__sub {
    display: none;
    list-style: none;
    margin: 0;
    padding-left: 20px;
  }

  &__sub-item {
    border-top: 1px dashed $border-color;
  }

  &__sub-link {
    display: block;
    padding: 10px 10px 10px 24px;
    color: $gray-800;
    position: relative;
    font-size: 13px;

    &:hover {
      color: $primary;
      text-decoration: none;
      background-color: $light;
    }

    &::before {
      content: "";
      display: block;
      width: 4px;
      height: 4px;
      background: #333;
      position: absolute;
      top: 18px;
      left: 8px;
    }
  }

  @media (max-width: 575px) {
    position: static;
  }

  @media (min-width: 1200px) {
    &__sub {
      display: none;
      position: absolute;
      z-index: 10;
      top: 0;
      left: 100%;
      background-color: $white;
      @include shadow("");
      min-width: 250px;
      padding-left: 0;
      border-left: 2px solid $primary;
      transform-origin: top left;
    }

    &__sub-item:first-child {
      border-top: 0;
    }

    &__sub-link {
      white-space: nowrap;
    }

    &__item--group:hover &__sub {
      display: block;
      animation: menu-float-show 0.3s;
    }
  }
}

@media (min-width: 1200px) {
  body.show-nav .h-nav__menu {
    display: block;
  }
}

.search {
  padding: 10px 20px 20px;
  border-bottom: 1px solid $border-color;

  .form-control {
    border-right: 0;
    border-color: $border-color;
  }

  .input-group-text {
    background: 0;
    border-left: 0;
  }

  @media (min-width: 1200px) {
    display: none;
    width: 350px;
    background-color: $white;
    padding: 10px 16px;
    @include shadow("xs");
    position: absolute;
    top: 100%;
    right: 0;
  }
}

.search-btn {
  display: none;

  @media (min-width: 1200px) {
    display: inline-flex;
    height: 50px;
    justify-content: center;
    align-items: center;
    color: $white;
    font-size: 24px;
    margin-left: 30px;

    &:hover {
      color: $white;
      text-decoration: none;
    }

    &__icon {
      height: 24px;
    }
  }
}

.cart-btn {
  display: inline-flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: 30px;

  &__icon {
    height: 24px;
  }

  &__number {
    display: flex;
    width: 15px;
    height: 15px;
    justify-content: center;
    align-items: center;
    color: $white;
    font-size: 9px;
    padding: 1px 0 0;
    line-height: 14px;
    text-align: center;
    border-radius: 50%;
    background-color: $danger;
    position: absolute;
    top: 10px;
    right: -8px;
  }
}

.cate {
  display: flex;
  padding: 20px;
  border-bottom: 1px solid $border-color;

  &__item {
    flex: 1;
    color: $primary;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  &__frame {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
  }

  &__img {
    max-height: 100%;
    object-fit: contain;
    object-position: 50% 50%;
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    margin-top: 8px;
  }

  @media (min-width: 1200px) {
    padding: 0;
    border: 0;
    position: absolute;
    top: -85px;
    right: 0;
    transform: translateY(-50%);

    &__item {
      &:not(:last-child) {
        margin-right: 50px;
      }
    }

    &__frame {
      height: 55px;
    }

    &__title {
      font-size: 16px;
      margin-top: 10px;
    }
  }
}
