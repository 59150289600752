.order {
  border-top: 1px solid $border-color;
  padding-top: 24px;
  margin-top: 10px;

  &__title {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: 1px solid $border-color;
    color: $primary;
    margin-bottom: 16px;
    padding-bottom: 16px;
  }

  &__subtitle {
    font-size: 14px;
    padding: 8px 20px;
    font-weight: 700;
    text-transform: uppercase;
    border-left: 8px solid $primary;
    background-color: rgba($primary, 0.2);
    margin-bottom: 20px;
  }

  &__list {
    @include list('solid', 8px);
    max-height: 210px;
    overflow: auto;
    padding-bottom: 10px;

    & > li {
      padding-right: 8px;
    }
  }

  &__total {
    padding: 14px 22px;
    text-align: right;
    font-weight: 700;
    border-top: 1px solid #ccc;
  }

  .form-group {
    margin-bottom: 20px;
  }

  .form-control {
    border-radius: 0;
  }

  @media (max-width: 767px) {
    padding: 20px 16px 0;
  }
}
